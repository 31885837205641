@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

*,
::after,
::before {
    box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.waviy {
  position: relative;
}
.waviy span {
  position: relative;
  display: inline-block;
  font-size: 40px;
  color: "#0D0E0F";
  text-transform: uppercase;
  animation: flip 5s infinite;
  animation-delay: calc(.3s * var(--i))
}

@keyframes flip {
  0%, 80% {
    transform: rotateY(360deg)
  }
  90%, 95% {
    transform: rotateY(0deg)
  }
}

.shadow{
  box-shadow: 0px 10px 66px 9px rgba(0,0,0,0.1);
}

.custom-shadow:hover{
  box-shadow: 0px 10px 66px 9px rgba(0,0,0,0.1);
}

.custom-shadow-white{
  box-shadow: 0px 10px 66px 9px rgba(255,255,255);
}

.markdown p {
  margin-bottom: 15px;
}

.markdown a {
  color: #fb6176;
}

/* Header styles */
.markdown h1 {
  font-size: 24px;
}

.markdown h2 {
  font-size: 20px;
}

.markdown h3 {
  font-size: 18px;
}

.markdown a:hover {
  color: #0066cc;
}

/* List styles */
.markdown ul,
.markdown ol {
  margin: 10px 0;
  padding-left: 20px;
}

/* Code block styles */
.markdown pre {
  background-color: #f1f1f1;
  padding: 10px;
  overflow: auto;
}

.markdown code {
  font-family: Consolas, monospace;
}

/* Blockquote styles */
.markdown blockquote {
  margin: 10px 0;
  padding-left: 20px;
  border-left: 2px solid #ccc;
}

/* Horizontal rule style */
.markdown hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 20px 0;
}
