/* externalEvent.css */
:root {
  --color-bark-green: #1f3e11;
  --color-green: #F1F3E8;
  --color-bright-green: ##B8F397;
  --color-brown: #543624;
  --color-beige: #ece5ce;
  --color-yellow: #f9cdad;
  --color-pink: #fe4365;
  --color-teal: #319795;
  --color-light-blue:#BBEBEB
;
}

/* hides the repactha */
.grecaptcha-badge
{
  opacity: 0;
}

.greenText{
  color: var(--color-bark-green);
}

.bubble {
  --r: 25px; /* the radius */
  --t: 40px; /* the size of the tail */
  z-index: 1;

  max-width: 290px;
  min-width: 90px;
  padding: calc(2*var(--r)/3);
  -webkit-mask:
    radial-gradient(var(--t) at var(--_d) 0,#0000 98%,#000 102%)
      var(--_d) 100%/calc(100% - var(--r)) var(--t) no-repeat,
    conic-gradient(at var(--r) var(--r),#000 75%,#0000 0)
      calc(var(--r)/-2) calc(var(--r)/-2) padding-box,
    radial-gradient(50% 50%,#000 98%,#0000 101%)
      0 0/var(--r) var(--r) space padding-box;
  background: var(--color-green);
  color: #000;
}
.left {
  --_d: 0%;
  border-left: var(--t) solid #0000;
  margin-right: var(--t);
  place-self: start;
}
.right {
  --_d: 100%;
  border-right: var(--t) solid #0000;
  margin-left: var(--t);
  place-self: end;
}
.image{
  padding:5px;
  width: 295px !important;
}
.host{
  background-color: var(--color-light-blue);
}

body {
  margin: 0;
  min-height: 100vh;
  /* display: grid; */
  /* place-content: center; */
  /* gap: 20px; */
  font-family: system-ui, sans-serif;
  font-size: 20px;
}


/* sticky bottom */
.main-content {
  flex: 1;
  overflow-y: auto;
  /* padding-bottom: 20px; /1* Adjust this value to provide enough space for the sticky section *1/ */
}

.sticky-bottom {
  position: sticky;
  border-top: 1px solid #ccc;
  bottom: 0;
  background-color: #e4e4e4;
  padding: 10px;
  max-width: 460px;
  margin:auto;
  z-index: 50;
}

.secure{
  background-color: #b2d2d1!important;
}

/* tooltip style */

.tooltip-container .my-tooptip {
  color: #fff;

  background-color: #FE4366;
}

.top-image{
  position:relative;
  top: -20px;
  left: -20px;
}


/* up and down buttons  */
.arrow-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 116px;
  z-index: 50;
}

.up{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 16px;
  z-index: 50;
}

.round-div {
  margin: 10px;
  border-radius: 50%;
  border: 1px solid lightgray;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow-button {
  color:lightgray;
  border: none;
  height:30px;
  width:30px;
  border-radius: 50%;
}

.arrow-button:hover {
  color: gray;
}


/* first groovy chat bubbles  */

.container {
  height: fit-content;
  margin:auto;
  margin-top:20px;
  display: grid;
  /* grid-template-columns: 200px 200px 200px; */
  place-items: center;
  font: 2vw system-ui;
  /* background-color: var(--color-yellow); */
}

.container div {
  /* transform: translatey(0px);
  animation: float 5s ease-in-out infinite; */
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 15px;
  color: white;
  background-color: var(--color-teal);
  padding: 30px;
  border-radius: 6px;
  position: relative;
  margin-bottom: 50px;
  box-shadow: 26px 23px 31px 9px rgba(0,0,0,.2);

  /* box-shadow: 20px 20px var(--color-blue); */
  /* font-family: "Baloo 2", cursive; */
}

.move{
  transform: translatey(0px);
  animation: float 5s ease-in-out infinite;
}

.join{
  /* -webkit-text-fill-color: var(--color-beige); */
  padding: 10px !important;
  /* background-color: var(--color-brown)!important; */

}

.move::after{
  transform: translatey(0px);
  animation: float2 5s ease-in-out infinite;
}

.container .move:after {
  transform: translatey(0px);
  animation: float2 5s ease-in-out infinite;
  content: ".";
  font-weight: bold;
  -webkit-text-fill-color: var(--color-teal);
  /* box-shadow: 26px 23px 31px 9px rgba(0,0,0,.2); */

  text-shadow: 22px 22px var(--color-blue);
  text-align: left;
  font-size: 55px;
  width: 55px;
  height: 11px;
  line-height: 30px;
  border-radius: 11px;
  background-color: var(--color-teal);
  position: absolute;
  display: block;
  bottom: -30px;
  left: 0;
  /* box-shadow: 26px 23px 31px 9px rgba(0,0,0,.2); */

  box-shadow: 22px 22px var(--color-blue);
  z-index: -2;
}

.rsvp{
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
  font-size: 15px;
  color: var(--color-brown);
  background-color: var(--color-beige);
  padding: 10px;
  border-radius: 3px;
  position: relative;
  margin-top: 50px;
  box-shadow: 5px 5px var(--color-blue);
  font-family: "Baloo 2", cursive;
}

.rsvp :hover{
  box-shadow: none;
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float2 {
  0% {
    line-height: 30px;
    transform: translatey(0px);
  }
  55% {
    transform: translatey(-20px);
  }
  60% {
    line-height: 10px;
  }
  100% {
    line-height: 30px;
    transform: translatey(0px);
  }
}
